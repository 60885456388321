import React, { ReactElement, useEffect } from 'react';
import { css } from '@emotion/react';
import { Layout } from '../components/Layout/Layout';
import { DealIntro } from '../modules/DealIntro/DealIntro';
import { DealIntroSave } from '../modules/DealIntro/DealIntroSave';
import { Feature } from '../modules/Feature/Feature';
import { FeatureParagraphCrown, FeatureParagraphMark, FeatureTitleSwirl } from '../styles/styles';
import { breakpoints } from '../styles/variables';
import { ContentAccordion } from '../modules/ContentAccordion/ContentAccordion';
import { ACCORDION_CONTENT } from '../data/accordion';
import { CAROUSEL_DATA } from '../data/carousel';
import { Carousel } from '../modules/Carousel/Carousel';
import { Checklist } from '../modules/Checklist/Checklist';
import { CHECKLIST_DATA } from '../data/checklist';
import { Features } from '../modules/Features/Features';
import { FEATURES_DATA } from '../data/features';
import { TestimonialsMore } from '../modules/Testimonials/TestimonialsMore';
import { TESTIMONIALS_DATA } from '../data/testimonials';
import { EyeCatchingVisualsSection } from '../modules/EyeCatchingVisualsSection/EyeCatchingVisualsSection';
import { SketchWowCostsSection } from '../modules/SketchWowCostsSection/SketchWowCostsSection';
import { SketchWowVersionsSection } from '../modules/SketchWowVersionsSection/SketchWowVersionsSection';
import { LifetimeSignup } from '../modules/Signup/LifetimeSignup';
import { DealPricing } from '../modules/DealPricing/DealPricing';
import { LifetimePlansPricing } from '../modules/LifetimePlans/LifetimePlansPricing';

const SaverPage = React.memo(
  (): ReactElement => (
    /*useEffect(()=>{
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: 'optimize.activate' })
  
     }, []),*/

    <Layout>
      <DealIntroSave />
      <Feature
        content={
          <>
            <p>Let’s face it. Most sketches, flowcharts and diagrams are trapped in 2002.</p>
            <p>
              It’s a sea of <span css={FeatureParagraphMark}>sameness</span>…
            </p>
            <p>Forgettable… uninspiring. </p>
            <p>
              Next time you want to brainstorm or map out your thoughts… make it memorable.
              <br />
              <br />
              How? Use <strong css={FeatureParagraphCrown}>SketchWow...</strong>
            </p>
          </>
        }
        direction="reversed"
        illustration={
          <img
            width={651}
            src="/sketchwow-laptop-woman.png"
            // src="feature-1.png"
            // srcSet="feature-1.png 1x, feature-1@2x.png 2x"
            alt="Diagrams"
            css={css`
              margin-left: -110px;
              transform: translateX(-24px);

              @media screen and (max-width: ${breakpoints.xl}px) {
                transform: none;
              }
            `}
            loading="lazy"
          />
        }
        title={
          <>
            <span css={FeatureTitleSwirl}>Nobody</span> wants <br />
            boring diagrams...
          </>
        }
      />
      <Feature
        content={
          <>
            <p>
              SketchWow is the perfect creative companion - so you can quickly condense and communicate your ideas, concepts and stories (in
              a clear and casual way)…. using a fresh & engaging, <span css={FeatureParagraphMark}>hand drawn</span> look.
              <br />
              <br />
              Once you use <strong>SketchWow…</strong>
              <br />
              <br />
              You’ll never create a typical DIE-a-gram or white-BORED ever again!
            </p>
          </>
        }
        illustration={
          <img
            width={500}
            src="/feature-2.webp"
            srcSet="/feature-2.webp 1x, /feature-2@2x.webp 2x"
            alt="Illustration"
            css={css`
              margin-right: 37px;
              transform: translate(-1px, -13px);

              @media screen and (max-width: ${breakpoints.xl}px) {
                max-width: 85% !important; // :sad_frog:
                transform: none;
              }
              @media screen and (max-width: ${breakpoints.lg}px) {
                max-width: 75% !important;
              }
            `}
            loading="lazy"
          />
        }
        title="What makes SketchWow refreshingly different?"
      />
      <ContentAccordion
        styles={css`
          margin-top: 69px;
        `}
        data={ACCORDION_CONTENT}
        title="It’s super-simple & fast to explain anything"
      />
      <Carousel
        data={CAROUSEL_DATA}
        subTitle="SketchWow will soon become your #1 go-to tool…  "
        title="Whenever you want to crank-out conversation-starter visuals or presentations, including:"
      />
      <Checklist data={CHECKLIST_DATA} title="SketchWow works great for…" />
      <Features data={FEATURES_DATA} title="Start creating amazing sketches that stand out…" />
      <TestimonialsMore data={TESTIMONIALS_DATA} title="You'll Love SketchWow Too, Just Like Our Customers Do..." />
      <EyeCatchingVisualsSection />
      <SketchWowCostsSection />
      <SketchWowVersionsSection />
      <DealPricing />
      <div style={{marginTop: '-125px'}}>
        <LifetimeSignup/>
      </div>
      {/* <DealPricing withTitle={true} />
      <LifetimePlansPricing/> */}
    </Layout>
  ),
);

export default SaverPage;
