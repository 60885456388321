import React, { ReactElement, useRef, useEffect } from 'react';
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

interface SignupProps {
  subTitle: ReactElement | string;
  title: ReactElement | string;
}

export const LifetimeSignup = ({ subTitle, title }: SignupProps): ReactElement => {
  const signupRef = useRef(null);
  const { isVisible } = useVisibilityDetection(signupRef);
  useEffect(()=>{
    const deal_qstr =  window.location.href.split('?')[1];
    if(typeof deal_qstr !== 'undefined'){
      document.getElementById("lifetime-deal-link").href = document.getElementById("lifetime-deal-link").href + "&" + deal_qstr;
    }

   }, []);
  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper isVisible={isVisible} ref={signupRef}>
        <Styled.Heading>
          <Styled.Title>{title}</Styled.Title>
          <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        </Styled.Heading>
        <Styled.Section>
          <Styled.Button id="lifetime-deal-link" href="https://checkout.sketchwow.com?add-to-cart=407">Get SketchWow</Styled.Button>
        </Styled.Section>
        <Styled.Footer>
          <p>
            SketchWow works flawlessly on <Styled.Doodle>Windows & Macs.</Styled.Doodle>
          </p>
        </Styled.Footer>
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
